* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--bkg-color);
}

a {
  color: unset;
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

#full_page {
  position: relative;
}