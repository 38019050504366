.maintenance__container {
  height: 100vh;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.helmet-icon {
  background-color: white;
  border-radius: 50%;
  padding: 30px;
  color: rgb(247, 211, 0);
  font-size: 8rem;
}

.maintenance-message {
  color: white;
  font-size: 3rem;
  padding: 20px 0;
  text-align: center;
}