.single-repo {
    color: rgb(144,160,184);
    font-size: 1.2rem;
    padding: 4% 0%;
    border-radius: 10px;
    margin: 3% 0;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: rgb(11,46,105);
    border: none;
}

.single-repo:hover {
    background-color: rgb(62, 75, 95);
}

.repo-list__container {
    background-color: rgb(10,37,85);
    padding: 1% 1.5%;
    min-width: 25%;
    height: 100%;
    text-align: left;
    border-radius: 20px;
    align-items: center;
}

.repo-header {
    color: rgb(129, 142, 167);
    font-size: 1.8rem;
    padding: 3% 2.5%;
    margin: 3% 0;
    border-bottom: 1px solid white;
}

.li__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.green-status {
    padding: 0 4%;
    font-size: 40%;
    color: lightgreen;
}

.metrics_container {
    width: 100%;
    height: 100%;
    padding-left: 2%;
}

.inner_metrics_container {
    background-color: rgb(10,37,85);
    border-radius: 20px;
    padding: 1% 1%;
    height: 100%;
}

.not_inner_metrics_container {
    background-color: rgb(10,37,85);
    border-radius: 20px;
    padding: 1% 1%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.code_icon {
    font-size: 5vh;
    margin-top: -30px;
}

.metrics_header {
    padding: 1% 1%;
    color: rgb(129, 142, 167);
    font-size: 1.8rem;
    border-bottom: 1px solid white;
}