* {
    margin: 0;
}

.error-message__container {
    margin: -180px 100px -170px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: -1;
}

.error-message {
    color: white;
    font-size: 50px;
}