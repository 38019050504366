.proj_icon {
    color: white;
    font-size: 2rem;
    padding: 1%;
}

.bar_container {
    width: 100%;
    height: 40%;
    background-color: rgb(11,46,105);
    overflow: hidden;
}

#weekly {
    margin-left: 10px;
    padding-top: 10px;
    color: white;
}