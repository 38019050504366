.nav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 100vw;
    padding: 0 10%;
    top: 6vh;
}

.logo {
    color: white;
    font-size: var(--fs-logo-header);
}

.nav_list {
    color: white;
    display: flex;
    justify-content: space-between;
    width: 30%;
    font-size: var(--40);
}

.nav_list > *:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}