.fb_searchbar_main {
    position: relative;
    display: flex;
    align-items: center;
    padding: none;
    width: 35%;
    height: 90px;
}

.fb_searchbar_main_search_icon {
    position: absolute;
    font-size: 20px;
    right: 20px;
    color: black;
}

.fb_searchbar_model {
    position: relative;
    display: flex;
    align-items: center;
    padding: none;
    width: 100%;
    height: 45px;
    padding: 0 1vw;
    margin: 1vh 0;
}

.fb_searchbar_model_search_icon {
    position: absolute;
    font-size: 20px;
    right: 45px;
    color: black;
}

input {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 50px;
    font-size: var(--10);
    background-color: white;
}

input[type="text"] {
    padding-left: 20px;
}

input[type="text"]:focus {
    outline: none;
}