.about-computing__container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
}

.about-computing__title {
    font-size: var(--fs-profession-title-home);
    padding: 0 15px;

    /* ff 3.6+ */
    background:-moz-linear-gradient(42deg, rgba(0, 255, 235, 1) 0%, rgba(7, 58, 187, 1) 80%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(42deg, rgba(0, 255, 235, 1) 0%, rgba(7, 58, 187, 1) 80%);

    /* opera 11.10+ */
    background:-o-linear-gradient(42deg, rgba(0, 255, 235, 1) 0%, rgba(7, 58, 187, 1) 80%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFEB', endColorstr='#073ABB', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(42deg, rgba(0, 255, 235, 1) 0%, rgba(7, 58, 187, 1) 80%);

    /* global 94%+ browsers support */
    background:linear-gradient(42deg, rgba(0, 255, 235, 1) 0%, rgba(7, 58, 187, 1) 80%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px black;
}

.about-computing__button-container {
    padding: 15px;
}

.about-computing__button {
    color: white;
    background-color: rgb(62, 152, 226);
    border-color: rgb(62, 152, 226);
    border-radius: 50px;
    font-size: 1rem;
    padding: 15px;
}

.about-computing__button:hover {
    color: white;
    background-color: rgb(62, 152, 226);
    border-color: rgb(62, 152, 226);
}