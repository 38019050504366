:root {
  font-family: "Times New Roman", Times, serif;

  --10: 1rem;
  --20: 1.2rem;
  --30: 1.4rem;
  --40: 1.6rem;
  --50: 2rem;
  --60: 2.5rem;
  --70: 3rem;
  --80: 3.8rem;
  --90: 4.2rem;
  --100: 6rem;

  --fs-professions-home: 3rem;
  --fs-location-home: 2rem;
  --fs-profession-title-home: 10rem;
  --fs-policy-links-footer: 1.2rem;
  --fs-icons-footer: 2rem;
  --fs-logo-header: 3.5rem;
  --fs-name-header: 2rem;

  --bkg-color: black;
  --tp-bkg-color: transparent;
}

@media screen and (min-width: 401px) and (max-width: 820px) {
  :root {
    --fs-professions-home: 3rem;
    --fs-location-home: 2rem;
    --fs-profession-title-home: 8rem;
    --fs-policy-links-footer: 1rem;
    --fs-icons-footer: 1.3rem;
  }

  .footer__container {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 2;
    grid-template-rows: 1;
  }

  .policy__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 1 / span 1 / span 1;
  }

  .copyright-host,
  .privacy-cookie {
    padding: 5px 0;
  }

  .all-links__container {
    grid-area: 1 / 2 / span 2 / span 1;
  }

  .social-media-icons__container {
    grid-area: 2 / 1 / span 1 / span 1;
  }
}

@media screen and (max-width: 400px) {
  :root {
    --fs-professions-home: 1.8rem;
    --fs-location-home: 1.5rem;
    --fs-profession-title-home: 4.5rem;
    --fs-policy-links-footer: 0.8rem;
    --fs-icons-footer: 0.9rem;
    --fs-logo-header: 2.5rem;
    --fs-name-header: 1.5rem;

    --marg-home: calc(-8vh + -54px);
  }

  .footer__container {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 2;
    grid-template-rows: 2;
  }

  .policy__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 1 / span 1 / span 1;
  }

  .copyright-host,
  .privacy-cookie {
    padding: 5px 0;
  }

  .all-links__container {
    grid-area: 1 / 2 / span 1 / span 1;
  }

  .social-media-icons__container {
    grid-area: 2 / 1 / span 1 / span 2;
  }

  .social-icon {
    padding: 25px 5px;
  }
}
