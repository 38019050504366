.about-science__container {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 0;
}

.about-science__title {
    font-size: var(--fs-profession-title-home);
    padding: 0 15px;

    /* ff 3.6+ */
    background:-moz-linear-gradient(139deg, rgba(0, 255, 15, 1) 0%, rgba(0, 128, 0, 1) 64%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(139deg, rgba(0, 255, 15, 1) 0%, rgba(0, 128, 0, 1) 64%);

    /* opera 11.10+ */
    background:-o-linear-gradient(139deg, rgba(0, 255, 15, 1) 0%, rgba(0, 128, 0, 1) 64%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008000', endColorstr='#00FF0F', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(139deg, rgba(0, 255, 15, 1) 0%, rgba(0, 128, 0, 1) 64%);

    /* global 94%+ browsers support */
    background:linear-gradient(139deg, rgba(0, 255, 15, 1) 0%, rgba(0, 128, 0, 1) 64%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px black;
}

.about-science__button-container {
    padding: 15px;
}

.about-science__button {
    color: white;
    background-color: darkgreen;
    font-size: 1rem;
    border-radius: 50px;
    border-color: darkgreen;
    padding: 15px;
}

.about-science__button:hover {
    background-color: darkgreen;
    border-color: darkgreen;
    color: white;
}