.intro-message__container {
  position: absolute;
  bottom: 5%;
}

.intro__message {
  color: white;
  font-size: var(--fs-professions-home);
}

#intro__location {
  color: red;
  font-size: var(--fs-location-home);
}
